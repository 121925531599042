<template>
  <div>
    <backButton />

    <template v-if="website">
      <div
        v-html="
          $t('Are you sure you want to delete website', {
            website: website.Name,
          })
        "
      ></div>
      <br />

      <cancelConfirmButtons
        @goBack="goBack"
        :confirmFunction="deleteWebsite"
        :loading="deleting"
        :confirmLabel="$t('Delete')"
      />
    </template>
    <template v-else-if="!$store.getters.error">
      <preloader></preloader>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      website: null,
      deleting: false,
    };
  },
  created() {
    this.getWebsite(this.$route.params.id);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    deleteWebsite() {
      this.deleting = true;

      this.$request.delete("/website/" + this.$route.params.id, null, (res) => {
        this.deleting = false;

        if (res.success) {
          this.getWebsites();
          this.$router.go(-1);
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
    getWebsite(id) {
      if (id) {
        this.$request.get("/website/" + id, null, (res) => {
          if (res.success) {
            this.website = res.data;
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    },
    getWebsites() {
      this.$request.get("/websites", null, (res) => {
        if (res.success) {
          this.$store.dispatch("setAllWebsites", res.data);
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
  },
};
</script>

<style>
</style>
